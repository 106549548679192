import { AuthGuard } from './core/guards/auth.guard';
import { userRole } from './core/utils/constants';
import { LayoutComponent } from './layouts/layout/layout.component';
import { VexRoutes } from '@vex/interfaces/vex-route.interface';
import { UnauthorizedComponent } from './pages/common/unauthorized/unauthorized.component';

export const appRoutes: VexRoutes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      // {
      //   path: '',
      //   loadComponent: () =>
      //     import('./pages/dashboards/dashboard-analytics/dashboard-analytics.component').then(
      //       (m) => m.DashboardAnalyticsComponent
      //     ),
      //   canActivate: [AuthGuard],
      //   data: { requiredRoles: [userRole.ADMIN, userRole.AD_MANAGER, userRole.REPORTER] }
      // },
      {
        path: 'dashboard',
        loadComponent: () =>
          import('./pages/dashboards/dashboard-analytics/dashboard-analytics.component').then(
            (m) => m.DashboardAnalyticsComponent
          ),
        canActivate: [AuthGuard],
        data: { requiredRoles: [userRole.ADMIN, userRole.AD_MANAGER, userRole.REPORTER,userRole.Checker] }
      },
      {
        path: 'client/add',
        loadComponent: () =>
          import('./pages/client/add-client/add-client.component').then(
            (m) => m.AddClientComponent
          ),
        canActivate: [AuthGuard],
        data: { requiredRoles: [userRole.ADMIN,userRole.AD_MANAGER,userRole.Checker] }
      },
      {
        path: 'client/edit/:id',
        loadComponent: () =>
          import('./pages/client/add-client/add-client.component').then(
            (m) => m.AddClientComponent
          ),
        canActivate: [AuthGuard],
        data: { requiredRoles: [userRole.ADMIN,userRole.AD_MANAGER,userRole.Checker] }
      },
      {
        path: 'client/list',
        loadComponent: () =>
          import('./pages/client/client-list/client-list.component').then(
            (m) => m.ClientListComponent
          ),
        canActivate: [AuthGuard],
        data: { requiredRoles: [userRole.ADMIN, userRole.AD_MANAGER,userRole.Checker] }
      },
      {
        path: 'campaign/add',
        loadComponent: () =>
          import('./pages/campaign/add-campaign/add-campaign.component').then(
            (m) => m.AddCampaignComponent
          ),
        canActivate: [AuthGuard],
        data: { requiredRoles: [userRole.ADMIN, userRole.AD_MANAGER,userRole.Checker] }
      },
      {
        path: 'campaign/edit/:id/:status_code/:type',
        loadComponent: () =>
          import('./pages/campaign/add-campaign/add-campaign.component').then(
            (m) => m.AddCampaignComponent
          ),
        canActivate: [AuthGuard],
        data: { requiredRoles: [userRole.ADMIN, userRole.AD_MANAGER,userRole.Checker] }
      },
      {
        path: 'campaign/list',
        loadComponent: () =>
          import('./pages/campaign/campaign-list/campaign-list.component').then(
            (m) => m.CampaignListComponent
          ),
        canActivate: [AuthGuard],
        data: { requiredRoles: [userRole.ADMIN, userRole.AD_MANAGER,userRole.Checker] }
      },
      {
        path: 'campaignapproval/list',
        loadComponent: () =>
          import(
            './pages/campaign/campaign-approval-list/campaign-approval-list.component'
          ).then((m) => m.CampaignApprovalListComponent),
        canActivate: [AuthGuard],
        data: { requiredRoles: [userRole.ADMIN,userRole.AD_MANAGER,userRole.Checker] }
      },
      {
        path: 'campaign/aduitlog',
        loadComponent: () =>
          import(
            './pages/campaign/campaign-auditlog/campaign-auditlog.component'
          ).then((m) => m.CampaignAuditlogComponent),
        canActivate: [AuthGuard],
        data: { requiredRoles: [userRole.ADMIN, userRole.Checker] }
      },
      {
        path: 'advertisement/add',
        loadComponent: () =>
          import(
            './pages/advertisement/create-advertisement/create-advertisement.component'
          ).then((m) => m.CreateAdvertisementComponent),
        canActivate: [AuthGuard],
        data: { requiredRoles: [userRole.ADMIN, userRole.AD_MANAGER] }
      },
      {
        path: 'advertisement/edit/:id/:status_code/:type',
        loadComponent: () =>
          import(
            './pages/advertisement/create-advertisement/create-advertisement.component'
          ).then((m) => m.CreateAdvertisementComponent),
        canActivate: [AuthGuard],
        data: { requiredRoles: [userRole.ADMIN, userRole.AD_MANAGER,userRole.Checker] }
      },
      {
        path: 'advertisement/list',
        loadComponent: () =>
          import(
            './pages/advertisement/advertisement-list/advertisement-list.component'
          ).then((m) => m.AdvertisementListComponent),
        canActivate: [AuthGuard],
        data: { requiredRoles: [userRole.ADMIN, userRole.AD_MANAGER,userRole.Checker] }
      },
      {
        path: 'adsapproval/list',
        loadComponent: () =>
          import(
            './pages/advertisement/ads-approval-list/ads-approval-list.component'
          ).then((m) => m.AdsApprovalListComponent),
        canActivate: [AuthGuard],
        data: { requiredRoles: [userRole.ADMIN,userRole.AD_MANAGER,userRole.Checker] }
      },
      {
        path: 'advertisement/aduitlog',
        loadComponent: () =>
          import(
            './pages/advertisement/ads-auditlog/ads-auditlog.component'
          ).then((m) => m.AdsAuditlogComponent),
        canActivate: [AuthGuard],
        data: { requiredRoles: [userRole.ADMIN, userRole.Checker] }
      },
      {
        path: 'weightage/list',
        loadComponent: () =>
          import(
            './pages/weightage/weightage-list/weightage-list.component'
          ).then((m) => m.WeightageListTable),
        canActivate: [AuthGuard],
        data: { requiredRoles: [userRole.ADMIN, userRole.AD_MANAGER] }
      },
      {
        path: 'budget/list',
        loadComponent: () =>
          import(
            './pages/budget/budget-list/budget-list.component'
          ).then((m) => m.BudgetListTable),
        canActivate: [AuthGuard],
        data: { requiredRoles: [userRole.ADMIN, userRole.AD_MANAGER] }
      },
      {
        path: 'user/add',
        loadComponent: () =>
          import('./pages/user/add-user/add-user.component').then(
            (m) => m.AddUserComponent
          ),
        canActivate: [AuthGuard],
        data: { requiredRoles: [userRole.ADMIN,userRole.Checker] }
      },
      {
        path: 'user/edit/:id',
        loadComponent: () =>
          import('./pages/user/add-user/add-user.component').then(
            (m) => m.AddUserComponent
          ),
        canActivate: [AuthGuard],
        data: { requiredRoles: [userRole.ADMIN, userRole.AD_MANAGER,userRole.Checker] }
      },
      {
        path: 'user/list',
        loadComponent: () =>
          import('./pages/user/user-list/user-list.component').then(
            (m) => m.UserListComponent
          ),
        canActivate: [AuthGuard],
        data: { requiredRoles: [userRole.ADMIN, userRole.AD_MANAGER,userRole.Checker] }
      },
      {
        path: 'user/aduitlog',
        loadComponent: () =>
          import(
            './pages/user/users-auditlog/users-auditlog.component'
          ).then((m) => m.UsersAuditlogComponent),
        canActivate: [AuthGuard],
        data: { requiredRoles: [userRole.ADMIN, userRole.Checker] }
      },
      {
        path: 'reports/campaign',
        loadComponent: () =>
          import('./pages/reports/reports/reports.component').then(
            (m) => m.ReportsComponent
          ),
        canActivate: [AuthGuard],
        data: { requiredRoles: [userRole.ADMIN, userRole.AD_MANAGER, userRole.REPORTER,userRole.Checker] }
      },
      {
        path: 'reports/ads',
        loadComponent: () =>
          import('./pages/reports/reports/reports.component').then(
            (m) => m.ReportsComponent
          ),
        canActivate: [AuthGuard],
        data: { requiredRoles: [userRole.ADMIN, userRole.AD_MANAGER, userRole.REPORTER,userRole.Checker] }
      },
      {
        path: 'reports/daywise',
        loadComponent: () =>
          import('./pages/reports/reports/reports.component').then(
            (m) => m.ReportsComponent
          ),
        canActivate: [AuthGuard],
        data: { requiredRoles: [userRole.ADMIN, userRole.AD_MANAGER, userRole.REPORTER,userRole.Checker] }
      },
      {
        path: 'reports/os',
        loadComponent: () =>
          import('./pages/reports/reports/reports.component').then(
            (m) => m.ReportsComponent
          ),
        canActivate: [AuthGuard],
        data: { requiredRoles: [userRole.ADMIN, userRole.AD_MANAGER, userRole.REPORTER,userRole.Checker] }
      },
      {
        path: 'reports/lang',
        loadComponent: () =>
          import('./pages/reports/reports/reports.component').then(
            (m) => m.ReportsComponent
          ),
        canActivate: [AuthGuard],
        data: { requiredRoles: [userRole.ADMIN, userRole.AD_MANAGER, userRole.REPORTER,userRole.Checker] }
      },
      {
        path: 'reports/page',
        loadComponent: () =>
          import('./pages/reports/reports/reports.component').then(
            (m) => m.ReportsComponent
          ),
        canActivate: [AuthGuard],
        data: { requiredRoles: [userRole.ADMIN, userRole.AD_MANAGER, userRole.REPORTER,userRole.Checker] }
      },
      {
        path: 'reports/country',
        loadComponent: () =>
          import('./pages/reports/reports/reports.component').then(
            (m) => m.ReportsComponent
          ),
        canActivate: [AuthGuard],
        data: { requiredRoles: [userRole.ADMIN, userRole.AD_MANAGER, userRole.REPORTER,userRole.Checker] }
      },
      {
        path: 'reports/ProcessedData',
        loadComponent: () =>
          import('./pages/reports/reports/reports.component').then(
            (m) => m.ReportsComponent
          ),
        canActivate: [AuthGuard],
        data: { requiredRoles: [userRole.ADMIN] }
      },
      {
        path: 'reports/AdsDisplayReport',
        loadComponent: () =>
          import('./pages/reports/reports/reports.component').then(
            (m) => m.ReportsComponent
          ),
        canActivate: [AuthGuard],
        data: { requiredRoles: [userRole.ADMIN] }
      },
      {
        path: 'reports/adDisplayReportSummary',
        loadComponent: () =>
          import('./pages/reports/reports/reports.component').then(
            (m) => m.ReportsComponent
          ),
        canActivate: [AuthGuard],
        data: { requiredRoles: [userRole.ADMIN] }
      },
      {
        path: 'reports/DaywiseConsolidateReport',
        loadComponent: () =>
          import('./pages/reports/reports/reports.component').then(
            (m) => m.ReportsComponent
          ),
        canActivate: [AuthGuard],
        data: { requiredRoles: [userRole.ADMIN] }
      },
      {
        path: 'reports/AdvertisementWiseConsolidate',
        loadComponent: () =>
          import('./pages/reports/reports/reports.component').then(
            (m) => m.ReportsComponent
          ),
        canActivate: [AuthGuard],
        data: { requiredRoles: [userRole.ADMIN] }
      },
      {
        path: 'reports/StatisticsReport',
        loadComponent: () =>
          import('./pages/reports/reports/reports.component').then(
            (m) => m.ReportsComponent
          ),
        canActivate: [AuthGuard],
        data: { requiredRoles: [userRole.ADMIN] }
      }

    ]
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
  {
    path: '**',
    redirectTo: 'dashboard'
  }
];
