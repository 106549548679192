
<div class="overflow-x-scroll">
    <h5 style="
    margin: 20px;
    font-size: 24px;
    font-weight: 600;
    text-align: center;">Current Ads</h5>
    <table mat-table [dataSource]="dataSource1" class="mat-elevation-z8">
      <!-- Name Column --> 
      <ng-container matColumnDef="image">
        <th mat-header-cell *matHeaderCellDef> AD Banner </th>
        <td mat-cell *matCellDef="let element"> 
          <ng-container *ngIf="bannerSize && element?.image_details?.en != null">
            <!-- <img [src]="element?.image_details?.en | imgs3download | async" class="h-16 align-middle"
              [ngStyle]="{'width.px': bannerSize.w, 'height.px': bannerSize.h}"
              onerror="this.src='assets/img/error_placeholder.jpg'" /> -->
              <a [href]="element?.link?.en" target="_blank"><img [src]="getImgsrc(element,'en')" class="h-16 align-middle"
                [ngStyle]="{'width.px': bannerSize.w, 'height.px': bannerSize.h}" 
                onerror="this.src='assets/img/error_placeholder.jpg'"/></a>
          </ng-container>
          <ng-container *ngIf="bannerSize && element?.image_details?.en == null && element?.image_details?.ar != null">
            <!-- <img [src]="element?.image_details?.ar | imgs3download | async" class="h-16 align-middle"
              [ngStyle]="{'width.px': bannerSize.w, 'height.px': bannerSize.h}"
              onerror="this.src='assets/img/error_placeholder.jpg'" /> -->
              <a [href]="element?.link?.en" target="_blank"><img [src]="getImgsrc(element,'ar')" class="h-16 align-middle"
              [ngStyle]="{'width.px': bannerSize.w, 'height.px': bannerSize.h}" 
              onerror="this.src='assets/img/error_placeholder.jpg'"/></a>
          </ng-container>
          <ng-container *ngIf="bannerSize && element?.image_details?.en == null && element?.image_details?.ar == null">
            <img src="assets/img/error_placeholder.jpg" class="h-16 align-middle"
              [ngStyle]="{'width.px': bannerSize.w, 'height.px': bannerSize.h}" />
          </ng-container>
        </td>
      </ng-container>
    
      <!-- Weight Column -->
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef>Advertisement Name</th>
        <td mat-cell *matCellDef="let element"> 
          {{element.ad_name}}
         </td>
      </ng-container>
      <ng-container matColumnDef="client_name">
        <th mat-header-cell *matHeaderCellDef> Client </th>
        <td mat-cell *matCellDef="let element"> 
         {{element.client_name}}
         </td>
      </ng-container>
    
      <!-- Symbol Column -->
      <ng-container matColumnDef="campaign_name">
        <th mat-header-cell *matHeaderCellDef>  Campaign Name </th>
        <td mat-cell *matCellDef="let element"> {{element.campaign_name}} </td>
      </ng-container>

      <ng-container matColumnDef="last_modified">
        <th mat-header-cell *matHeaderCellDef>  Updated Date </th>
        <td mat-cell *matCellDef="let element"> {{dateChanges(last_modified)}} </td>
      </ng-container>
      
      <tr mat-header-row *matHeaderRowDef="displayedColumnsnew"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsnew;"></tr>
    </table>
    <div *ngIf="(!dataSource1?.data || dataSource1.data.length === 0)" class="flex justify-center items-center py-4" style="
    font-size: 16px;
    font-weight: 600;">
      No data found
    </div>

    <h5 style="
    margin: 20px;
    font-size: 24px;
    font-weight: 600;
    text-align: center;">Upcoming Ads</h5>

<table mat-table [dataSource]="dataSource2" class="mat-elevation-z8">
      <!-- Name Column -->
      <ng-container matColumnDef="image">
        <th mat-header-cell *matHeaderCellDef> AD Banner </th>
        <td mat-cell *matCellDef="let element"> 
          <ng-container *ngIf="bannerSize && element?.image_details?.en != null">
            <img [src]="element?.image_details?.en | imgs3download | async" class="h-16 align-middle"
              [ngStyle]="{'width.px': bannerSize.w, 'height.px': bannerSize.h}"
              onerror="this.src='assets/img/error_placeholder.jpg'" />
          </ng-container>
          <ng-container *ngIf="bannerSize && element?.image_details?.en == null && element?.image_details?.ar != null">
            <img [src]="element?.image_details?.ar | imgs3download | async" class="h-16 align-middle"
              [ngStyle]="{'width.px': bannerSize.w, 'height.px': bannerSize.h}"
              onerror="this.src='assets/img/error_placeholder.jpg'" />
          </ng-container>
          <ng-container *ngIf="bannerSize && element?.image_details?.en == null && element?.image_details?.ar == null">
            <img src="assets/img/error_placeholder.jpg" class="h-16 align-middle"
              [ngStyle]="{'width.px': bannerSize.w, 'height.px': bannerSize.h}" />
          </ng-container>
        </td>
      </ng-container>
    
      <!-- Weight Column -->
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef>Advertisement Name</th>
        <td mat-cell *matCellDef="let element"> 
          {{element.ad_name}}
         </td>
      </ng-container>
      <ng-container matColumnDef="client_name">
        <th mat-header-cell *matHeaderCellDef> Client </th>
        <td mat-cell *matCellDef="let element"> 
         {{element.client_name}}
         </td>
      </ng-container>
    
      <!-- Symbol Column -->
      <ng-container matColumnDef="campaign_name">
        <th mat-header-cell *matHeaderCellDef>  Campaign Name </th>
        <td mat-cell *matCellDef="let element"> {{element.campaign_name}} </td>
      </ng-container>

      <ng-container matColumnDef="last_modified">
        <th mat-header-cell *matHeaderCellDef>  Updated Date </th>
        <td mat-cell *matCellDef="let element"> {{dateChanges(last_modified)}} </td>
      </ng-container>
      
      <tr mat-header-row *matHeaderRowDef="displayedColumnsnew"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsnew;"></tr>
    </table>

    <div *ngIf="(!dataSource2?.data || dataSource2.data.length === 0)" class="flex justify-center items-center py-4" style="
    font-size: 16px;
    font-weight: 600;">
      No data found
    </div>

</div>




  
  