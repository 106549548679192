import { HttpClient, HttpHeaders, HttpParams,HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppInitializerService } from './app-initializer.service';
import { catchError, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdService {
  private headers: HttpHeaders;
  private baseUrl = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.set('Content-Type', 'application/json');
  }

  getAdList(
    pageNo: any = null,
    pageSize: any = null,
    searchText: any = null,
    statusCode: any = null,
    sortBy: any = null,
    sortOrder: any = null,
    ad_approval_status:any = null
  ): Observable<any> {
    const apiUrl = this.baseUrl + '/admin/ads';
    let params = new HttpParams();
    params = pageNo != null ? params.set('offset', pageNo) : params;
    params = pageSize != null ? params.set('limit', pageSize) : params;
    params = searchText != null ? params.set('title', searchText) : params;

    params = statusCode != null ? params.set('status_code', statusCode) : params;
    params = sortBy ? params.set('sort_by', sortBy) : params;
    params = sortOrder ? params.set('sort_order', sortOrder) : params;
    params = ad_approval_status ? params.set('ad_approval_status',ad_approval_status):params;

    return this.http.get<any>(apiUrl, { params, headers: this.headers });
  }

  getAdListNew(
    pageNo: any = null,
    pageSize: any = null,
    searchText: any = null,
    statusCode: any = null,
    sortBy: any = null,
    sortOrder: any = null,
    ad_approval_status:any = null
  ): Observable<any> {
    const apiUrl = this.baseUrl + '/admin/ads/parent/list';
    let params = new HttpParams();
    params = pageNo != null ? params.set('offset', pageNo) : params;
    params = pageSize != null ? params.set('limit', pageSize) : params;
    params = searchText != null ? params.set('title', searchText) : params;

    // params = statusCode != null ? params.set('status_code', statusCode) : params;
    params = sortBy ? params.set('sort_by', sortBy) : params;
    params = sortOrder ? params.set('sort_order', sortOrder) : params;
    params = ad_approval_status ? params.set('ad_approval_status',ad_approval_status):params;

    return this.http.get<any>(apiUrl, { params, headers: this.headers });
  }


  getAdListapproval(
    pageNo: any = null,
    pageSize: any = null,
    searchText: any = null,
    statusCode: any = null,
    sortBy: any = null,
    sortOrder: any = null,
    ad_approval_status:any = null
  ): Observable<any> {
    const apiUrl = this.baseUrl + '/admin/ads/temporary/data/list';
    let params = new HttpParams();
    params = pageNo != null ? params.set('offset', pageNo) : params;
    params = pageSize != null ? params.set('limit', pageSize) : params;
    params = searchText != null ? params.set('title', searchText) : params;

    // params = statusCode != null ? params.set('status_code', statusCode) : params;
    params = sortBy ? params.set('sort_by', sortBy) : params;
    params = sortOrder ? params.set('sort_order', sortOrder) : params;
    params = ad_approval_status ? params.set('ad_approval_status',ad_approval_status):params;

    return this.http.get<any>(apiUrl, { params, headers: this.headers });
  }

  getAdDetails(adId: any,status:any): Observable<any> {
    // const apiUrl = this.baseUrl + '/admin/ads/' + adId + '/'+status;
    // return this.http.get<any>(apiUrl, { headers: this.headers });
    let params:any = {
        status: status
    }
    const apiUrl = this.baseUrl + '/admin/ads/' + adId;
    return this.http.get<any>(apiUrl, { headers: this.headers,params:params });
  }

  getdropdownAds(status:any): Observable<any> {
    const apiUrl = this.baseUrl + '/admin/ads/dropdown/activeads/list?status_code=' + status;
    return this.http.get<any>(apiUrl, { headers: this.headers });
  }

  getdropdownCampaign(status:any): Observable<any> {
    const apiUrl = this.baseUrl + '/admin/campaign/dropdown/activecampaign/list?status_code=' + status;
    return this.http.get<any>(apiUrl, { headers: this.headers });
  }

  getAdsEdit(adId: any,status:any,type:any): Observable<any> {
    let apiUrl:any = ''
    if(type == 'parent'){
      apiUrl = this.baseUrl + '/admin/ads/' + adId;
    }else{
      apiUrl = this.baseUrl + '/admin/ads/temporary/' + adId;
    }
    let params:any = {
        status: status
    }
    return this.http.get<any>(apiUrl, { headers: this.headers,params:params });
  }

  getAdDetailsParent(adId: any,status:any): Observable<any> {
    let params:any = {
        status: status
    }
    const apiUrl = this.baseUrl + '/admin/ads/parent/' + adId;
    return this.http.get<any>(apiUrl, { headers: this.headers,params:params });
  }

  getRegenerateAdImage(adId: any): Observable<any> {
    const apiUrl = this.baseUrl + '/admin/ads/regenerateAdImage/' + adId;
    return this.http.get<any>(apiUrl, { headers: this.headers});
  }

  getAdDetailsTemporary(adId: any,status:any): Observable<any> {
    let params:any = {
        status: status
    }
    const apiUrl = this.baseUrl + '/admin/ads/temporary/' + adId;
    return this.http.get<any>(apiUrl, { headers: this.headers,params:params });
  }

  getAuditlog(data: any): Observable<any> {
    const apiUrl = this.baseUrl + '/admin/ads/getAdStatusHistory';
    return this.http.post<any>(apiUrl, data, { headers: this.headers });
  }


  getAdsAuditlog(data: any): Observable<any> {
    const apiUrl = this.baseUrl + '/admin/ads/consolidateAdStatusHistoryFetch';
    return this.http.post<any>(apiUrl, data, { headers: this.headers });
  }

  getUserAuditlog(data: any): Observable<any> {
    const apiUrl = this.baseUrl + '/admin/user/audit/consolidateUserHistoryFetch';
    return this.http.post<any>(apiUrl, data, { headers: this.headers });
  }
  

  getcampaignAuditlog(data: any): Observable<any> {
    const apiUrl = this.baseUrl + '/admin/campaign/getCampaignStatusHistory';
    return this.http.post<any>(apiUrl, data, { headers: this.headers });
  }

  getconsolidateCampaignAuditlog(data: any): Observable<any> {
    const apiUrl = this.baseUrl + '/admin/campaign/consolidateCampaignStatusHistoryFetch';
    return this.http.post<any>(apiUrl, data, { headers: this.headers });
  }

  getAdsapi(): Observable<any> {
    const apiUrl = this.baseUrl + '/ads';
    return this.http.get<any>(apiUrl, { headers: this.headers });
  }

  getCampaignEndAdsID(id:any): Observable<any> {
    const apiUrl = this.baseUrl + '/admin/report/getCampaignEndAds/'+id;
    return this.http.get<any>(apiUrl, { headers: this.headers });
  }

  createAd(data: any): Observable<any> {
    const apiUrl = this.baseUrl + '/admin/ads';
    return this.http.post<any>(apiUrl, data, { headers: this.headers });
  }

  updateAd(adId: any, data: any): Observable<any> {
    const apiUrl = this.baseUrl + '/admin/ads/' + adId;
    return this.http.put<any>(apiUrl, data, { headers: this.headers });
  }

  s3SignedUrl(data: any, s3Type: any): Observable<any> {
    const apiUrl = this.baseUrl + '/admin/ads/signed-url/' + s3Type;
    return this.http.post<any>(apiUrl, data, { headers: this.headers });
  }

  uploadFileToS3(uploadPath: string, file: File, fields: any): Observable<any> {
    const formData = new FormData();
    Object.keys(fields).forEach((key) => formData.append(key, fields[key]));
    formData.append('file', file);

    return this.http.post(uploadPath, formData);
  }

  checkUrlExists(url: string): Observable<boolean> {
    const options = {
      headers: new HttpHeaders(),
      observe: 'response' as 'response',
      responseType: 'text' as 'json'
    };

    return new Observable<boolean>((observer) => {
      this.http.head(url, options).subscribe(
        response => {
          if (response.status === 200) {
            observer.next(true); 
          } else {
            observer.next(false); 
          }
        },
        error => {
          observer.next(false); 
        }
      );
    });
  }
}
