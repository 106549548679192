import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppInitializerService } from './app-initializer.service';

@Injectable({
  providedIn: 'root'
})
export class LookupService {
  private headers: HttpHeaders;
  private baseUrl = environment.apiUrl;
  ipUrl = 'https://api.ipify.org?format=json';
  constructor(
    private http: HttpClient
  ) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.set('Content-Type', 'application/json');
  }
  getIp(): Observable<any> {
    const targetUrl = '/api?format=json'
    return this.http.get<any>(targetUrl);
    
  }

 
  
  getAdImageSizes(): Observable<any> {
    const apiUrl = this.baseUrl + '/admin/lookup/ads/image_sizes';
    return this.http.get<any>(apiUrl, { headers: this.headers });
  }

  getAdStatus(): Observable<any> {
    const apiUrl = this.baseUrl + '/admin/lookup/ads/status';
    return this.http.get<any>(apiUrl, { headers: this.headers });
  }

  getCampaignStatus(): Observable<any> {
    const apiUrl = this.baseUrl + '/admin/lookup/campaign/status';
    return this.http.get<any>(apiUrl, { headers: this.headers });
  }
}
